.dialog {
    display: flex;
    flex-direction: row;
}

.imageCon {
    display: flex;
    flex-direction: column;
    max-height: 432px;
    max-width: 300px;
}

.imageCon img{
    padding: 10px;
}
@media only screen and (max-width: 600px) {
    .dialog {
        display: flex;
        flex-direction: column;
    }
    .imageCon {
        display: flex;
        flex-direction: row;
    }
    .imageCon img{
        padding: 10px;
        max-height: 432px;
        max-width: 50%;
    }
}