.scrollable-container {
  overflow-x: hidden; /* Enable horizontal scrolling */
  display: flex; /* Use flexbox for horizontal alignment */
  margin: 0 auto; /* Center the container */
  width: 100%; /* Ensure the container takes up the full width */
}

.scrollable-content {
  display: flex; /* Use flexbox for horizontal alignment */
}
@media (min-width: 768px) {
  .scrollable-container {
      width: calc(100vw / 1.5 - 5px);
    }
    .scrollable-content {
      width: calc(100vw / 1.5 - 5px);
    }
}

.box {
  flex-shrink: 0; /* Prevent boxes from shrinking */
  margin-right: 5px; /* Add margin between boxes */
  width: calc(100vw / 9 - 15px); /* Adjust width for three boxes per row on mobile */
  height: 50px;
}

.box img {
  width: 100%; /* Adjust width for three boxes per row on mobile */
  height: 100%;
}

.box.featured {
  width: calc(100vw / 9 - 5px); /* Adjust width for three boxes per row on mobile */
  border-width: 5px;
  border-style: solid;
  border-color: #655df0; /* Border color for the inner border */
  border-radius: 5px; /* Add border radius for a rounded frame */
  box-sizing: border-box; /* Ensure padding is included in the total width */
  margin-left: 5px;
  margin-right: 5px;
  height: 60px;
}

.box.featured::before {
  content: ''; /* Create a pseudo-element for the outer border */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 25px; /* Add border radius for a rounded frame */
}


@media (max-width: 768px) {
  .box {
    width: calc(100vw / 3 - 15px); /* Adjust width for three boxes per row on mobile */
  }

  .box.featured {
    width: calc(100vw / 3 - 5px); /* Adjust width for three boxes per row on mobile */
  }

  .scrollable-container {
    overflow-x: scroll;
  }
}
