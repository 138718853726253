.scrollable-container1 {
    position: relative;
    width: 100%;
    height: 20rem;
    overflow-x: hidden;
    display: flex;
    margin: 0 auto;
  }
  
  .scrollable-content1,
  .scrollable-content2 {
    position: absolute;
    top: 0;
    display: flex;
    animation-duration: 10s; /* Doubled duration to match both content */
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  .scrollable-content1 {
    left: 0;
    animation: primary 10s linear infinite;
  }
  
  .scrollable-content2 {
    right: 0;
    animation: secondary 10s linear infinite;
  }
  
  .box1 {
    flex-shrink: 0;
    height: 100px;
    margin-right: 5px;
    background-color: lightblue;
    width: 100px;
  }
  
  .box.featured1 {
    width: calc(33.333% - 5px);
    height: 150px;
    border: 1px solid lightgreen;
  }
  
  @media (max-width: 768px) {
    .box1 {
      width: calc(33.333% - 5px);
    }
    .box.featured1 {
      width: calc(33.333% - 5px);
    }
    .scrollable-container1 {
      overflow-x: scroll;
    }
  }
  
  @keyframes primary {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100%)); /* Adjusted for margin */
    }
  }
  
  @keyframes secondary {
    from {
      transform: translateX(calc(100%)); /* Adjusted for margin */
    }
    to {
      transform: translateX(0);
    }
  }
  