.centered {
	position: absolute;
	top: 80%;
	left: 25%;
	transform: translate(-50%, -50%);
}

.container {
	position: relative;
	text-align: center;
	color: white;
}



.po_item {
	display: inline-block;
	width: 100%;
	text-align: center;
	margin: 0.5rem auto;
	position: relative;
	background: var(--secondary-color);
	padding: 6px;
	border: 1px solid var(--secondary-color);
	transition: 0.3s ease;
	font-size: 0;
	min-height: 300px;
}

.po_item img {
	max-width: 100%;
}

.po_item .content {
	position: absolute;
	height: 0;
	width: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: var(--overlay-color);
	z-index: 1;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	transition: 0.3s ease-in-out;
	opacity: 0;
	border: 1px solid black;
}

.po_item .content {
	opacity: 0;
	transition-delay: 1s;
	transition: 0.3s ease;
	font-size: 20px;
}

.po_item:hover {
	box-shadow: 0 10px 15px 0 rgba(255, 255, 255, 0.25);
}

.po_item:hover .content {
	height: calc(100% - 30px);
	width: calc(100% - 30px);
	opacity: 1;
}

.po_item:hover .content {
	opacity: 1;
	color: 'white'
}

.po_item .content a {
	background: var(--bg-color);
	border: solid 1px var(--text-color);
	padding: 4px 8px;
	text-align: center;
	font-size: 1rem;
	color: aliceblue;
}

.po_item .content a:hover {
	text-decoration: none;
}

.subtitle {
	color: #F5F5FA;
}

body {
	background: #262a2b;
}

.tilesWrap {
	padding: 0;
	margin: 50px auto;
	list-style: none;
	text-align: center;
}

.tilesWrap li {
	display: inline-block;
	width: 20%;
	min-width: 190px;
	max-width: 230px;
	padding: 40px 20px 40px;
	position: relative;
	vertical-align: top;
	font-family: 'helvetica', san-serif;
	background: #26273b;
	border: 1px solid #252727;
	text-align: left;
	margin: 10px;
}

.tilesWrap li:hover {
	display: inline-block;
	width: 20%;
	min-width: 190px;
	max-width: 230px;
	padding: 0px 20px 40px;
	position: relative;
	vertical-align: top;
	margin: px;
	font-family: 'helvetica', san-serif;
	background: #262a2b;
	border: 1px solid #252727;
	text-align: left;
	margin: 10px;
}

.tilesWrap li h2 {
	font-size: 114px;
	margin: 0;
	position: absolute;
	opacity: 0.2;
	background: -webkit-linear-gradient(#1bffff, #ac6df8);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	top: 50px;
	right: 10px;
	transition: all 0.3s ease-in-out;
}

.tilesWrap li h3 {
	font-size: 20px;
	color: #F5F5FA;
	margin-bottom: 5px;
}

.tilesWrap li p {
	font-size: 16px;
	line-height: 18px;
	color: #F5F5FA;
	margin-top: 5px;
}

.tilesWrap li button {
	background: transparent;
	border: 1px solid #b7b7b7;
	padding: 10px 20px;
	color: #b7b7b7;
	border-radius: 3px;
	position: relative;
	transition: all 0.3s ease-in-out;
	transform: translateY(-40px);
	opacity: 0;
	cursor: pointer;
	overflow: hidden;
}

.tilesWrap li button:before {
	content: '';
	position: absolute;
	height: 100%;
	width: 120%;
	background: #b7b7b7;
	top: 0;
	opacity: 0;
	left: -140px;
	border-radius: 0 20px 20px 0;
	z-index: -1;
	transition: all 0.3s ease-in-out;

}

.tilesWrap li:hover button {
	transform: translateY(5px);
	opacity: 1;
}

.tilesWrap li button:hover {
	color: #262a2b;
}

.tilesWrap li button:hover:before {
	left: 0;
	opacity: 1;
}

.tilesWrap li:hover h2 {
	top: 0px;
	opacity: 1;
	position: relative;
}

.tilesWrap li:before {
	content: '';
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	z-index: -1;
	background: #fff;
	transform: skew(2deg, 2deg);
}

.tilesWrap li:after {
	content: '';
	position: absolute;
	width: 40%;
	height: 100%;
	left: 0;
	top: 0;
	background: rgba(255, 255, 255, 0.02);
}

.tilesWrap li:before {
	background: #C9FFBF;
	background: -webkit-linear-gradient(to right, #FFAFBD, #C9FFBF);
	background: linear-gradient(to right, #FFAFBD, #C9FFBF);
}

.tilesWrap li:nth-child(2):before {
	background: #f2709c;
	background: -webkit-linear-gradient(to right, #ff9472, #f2709c);
	background: linear-gradient(to right, #ff9472, #f2709c);
}

.tilesWrap li:nth-child(3):before {
	background: #c21500;
	background: -webkit-linear-gradient(to right, #ffc500, #c21500);
	background: linear-gradient(to right, #ffc500, #c21500);
}

.tilesWrap li:nth-child(4):before {
	background: #FC354C;
	background: -webkit-linear-gradient(to right, #0ABFBC, #FC354C);
	background: linear-gradient(to right, #0ABFBC, #FC354C);
}

.blured:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
	filter: blur(20px);
	background: url(../../Resources/co_logo.png);
	background-attachment: fixed;
	background-size: cover;
}

.blured {
	margin: auto;
	position: relative;
	z-index: 1;
	font-size: calc(10px + 2vw);
	color: red;
	border: solid 0px;
	overflow: hidden;

}

.img {
	background: url('../../Resources/co_logo.png');
	background-attachment: fixed;
	/* prepare for the blurry piece to add */
	margin: 0;
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	height: 800px;
	width: 100%;
}


@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

:root {
	--yellow: #ffdd40;
	--dark: #2f313a;
	--deg: -86deg;
	--trans: all 0.4s ease 0s;
}



.content {
	width: 90vmin;
}

h2 {
	text-align: center;
}

.team {
	padding: 2em 0 2em 2.5em;
	margin: 0;
}

.member {
	margin: 1.5em 0 0.5em;
	padding: 0.73em;
	background: #2f313a;
	color: whitesmoke;
	position: relative;
	list-style: none;
	display: inline-block;
	transform: scale(0.85);
	transition: var(--trans);
}

.member:nth-of-type(even) {
	text-align: right;
	background: #2f313a;
	color: whitesmoke;
}

.thumb {
	width: 13vmin;
	height: 13vmin;
	float: left;
	margin-right: 1.25em;
	background: linear-gradient(var(--deg),
			var(--dark) 0 70%,
			#3f5efb 0% 100%);
	transform: rotate(-4deg);
	transition: var(--trans);
	border-radius: 0.25em;
	overflow: hidden;
	margin-left: -3em;
	padding: 0.5em;
}

.member:nth-of-type(even) .thumb {
	--deg: 86deg;
	float: right;
	margin-left: 2em;
	margin-right: -3em;
	transform: rotate(4deg);
}

.thumb img {
	width: 100%;
	height: 100%;
	border-radius: 0.25em;
	filter: grayscale(1);
	background: var(--dark);
}

.member:hover {
	transform: scale(1);
	transition: var(--trans);
	color: #141526;
	filter: drop-shadow(0px 20px 10px #0008);
	background: rgb(172, 109, 248);
	background: linear-gradient(90deg, rgba(172, 109, 248, 1) 0%, rgba(27, 255, 255, 1) 100%);
}

.member:hover .thumb {
	padding: 0.1em;
	transition: var(--trans);
	transform: rotate(-1deg);
	--deg: -89deg;
}

.member:nth-of-type(even):hover .thumb {
	--deg: 91deg;
}

.member:hover .thumb img {
	filter: none;
	transition: var(--trans);
}

.description {
	padding-top: 1vmin;
}

.description p {
	padding: 0 2em;
	margin-bottom: 1em;
	font-size: large;
}

.description h3 {
	background: linear-gradient(182deg, #fff0 100%, var(--dark) 0 100%);
	display: inline;
	transform: rotate(-2deg);
	position: absolute;
	margin: 0;
	margin-top: -2.25em;
	left: 9vmin;
	padding: 0.5em 0.75em;
	color: #1bffff;
	font-weight: bold;
	border-radius: 0.25em;
	font-size: 1.9em;
	transform-origin: left bottom;
}

.member:nth-of-type(even) h3 {
	left: inherit;
	right: 9vmin;
	transform: rotate(2deg);
	transform-origin: right bottom;
	background: linear-gradient(-182deg, rgba(255, 0, 0, 0) 100%, var(--dark) 0 100%);
}

.member:hover h3 {
	color: #ac6df8;
	transition: var(--trans);
	transform: rotate(0deg);
	background: linear-gradient(180deg, #fff0 0%, var(--dark) 0 100%);
}

.co-funder:after {
	content: "Back-end";
	font-size: 0.75em;
	position: absolute;
	top: -1.5em;
	background: wheat;
	right: 4em;
	transform: rotate(3deg);
	padding: 0.35em 0.75em 0.5em;
	border-radius: 0.25em;
	color: var(--dark);
	font-weight: bold;
}

.co-funder:nth-of-type(even):after {
	right: inherit;
	left: 0;
	transform: rotate(-3deg);
}

.description p a {
	display: inline-block;
	margin: 0.5em 0 0 0;
	background: rgb(63, 144, 251);
	color: #e7e7e8;
	padding: 0.1em 0.5em 0.35em;
	border-radius: 0.5em;
	text-decoration: none;
}

.description p a:hover {
	transition: var(--trans);
	color: #fff;
	background: rgba(63, 94, 251, 1);
	font-weight: bold;
}

.description p a img {
	float: left;
	width: 22px;
	filter: invert(1);
	border-radius: 0.15em;
	padding: 2px;
	background: #fff;
	margin-right: 2px;
}