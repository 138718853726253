* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Exo;
}

@font-face {
  font-family: Exo;
  src: url(./Exo2-Medium.ttf);
}

.main {
  margin-block: 10%;
  width: 80%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #141526;
}
@media only screen and (max-width: 600px) {
  .main {
    display: block;
    width: 100%;
  }
}

.profile-card {
  border-style: solid;
  position: relative;
  font-family: sans-serif;
  width: 220px;
  height: 220px;
  background: rgb(172, 109, 248);
  background: linear-gradient(
    90deg,
    rgba(172, 109, 248, 1) 0%,
    rgba(27, 255, 255, 1) 100%
  );
  padding: 30px;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
  margin: 0 25px;
}

.profile-card:hover {
  border-radius: 10px;
  height: 260px;
  width: 230px;
}

.profile-card .img {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.6s;
  z-index: 99;
  border-radius: 50%;
}

.profile-card:hover .img {
  transform: translateY(-60px);
  height: 90%;
}

.img img {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
}

.profile-card:hover img {
  border-radius: 10px;
}

.caption {
  transform: translateY(-60px);
  opacity: 0;
  transition: 0.6s;
}

.profile-card:hover .caption {
  opacity: 1;
}

.caption h3 {
  font-size: 21px;
  font-family: sans-serif;
}

.caption p {
  font-size: 15px;
  color: #0c0e11;
  font-family: sans-serif;
  margin: 2px 0 9px 0;
}

.caption .social-links a {
  color: #333;
  margin-right: 15px;
  font-size: 9px;
  transition: 0.6s;
}

.social-links a:hover {
  color: #0c52a1;
}
/*about*/
.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.about-section {
  margin-bottom: 30px;
}

h2 {
  color: rgba(27, 255, 255, 1);
  margin-bottom: 10px;
}

p {
  line-height: 1.6;
  color: #666;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
