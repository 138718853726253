.contact__form .form-control {
    padding: 1.375rem .75rem;
    line-height: 1.5;
    color:var(--text-color);
    background-color: var(--bg-color);;
    border-radius: 0 !important;
    border: 1px solid white;
}
.contact__form .form-control::-webkit-input-placeholder{
    color: #ffff
}

.contact__form input.form-control {
    margin-bottom: 2em;
    height: calc(2.5em + .75rem + 2px);
    background-color: #1F1F29;
    color: #ffff;
}
.contact__form textarea.form-control{
    background-color: #1F1F29;
    color: #ffff;
}

button.btn.ac_btn:hover {
    color:var(--text-color)
}

button.btn.ac_btn {
    padding: 4px 19px;
    color: var(--text-color);
    position: relative;
    border: 2px white solid;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    cursor: pointer;
    border-radius: 0;
    margin-right: 20px;
    background-color: var(--bg-color)
}
body {
    background-color: #000;
  }

.ac_btn:hover {
    box-shadow: 8px 8px 0px white, -8px -8px 0px white;
}



.loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: 999999999;
    background: var(--text-color);
    transform: translateX(100%);
    animation: shift-rightwards 1s ease-in-out infinite;
    animation-delay: .3s;
}

@keyframes shift-rightwards {
    0% {
        transform: translateX(-100%);
    }
    40% {
        transform: translateX(0%);
    }
    60% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}