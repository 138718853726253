html,
body {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: "#141526";
    color: var(--text-color);
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-left: 10px solid var(--primary-color);
    border-right: 10px solid var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
